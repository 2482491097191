import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   *
   *     Errors messages and codes mapped to
   *     fields or non fields errors.
   *     Example:
   *     {
   *         field_name: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ],
   *         other_field: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ],
   *         nonFieldErrors: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ]
   *     }
   *
   */
  ExpectedErrorType: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

/**
 * Archive account and revoke refresh tokens.
 *
 * User must be verified and confirm password.
 */
export type ArchiveAccount = {
  __typename?: 'ArchiveAccount';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type AuthenticateToken = {
  __typename?: 'AuthenticateToken';
  ok?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  team?: Maybe<TeamType>;
};

export type CsvFileType = {
  __typename?: 'CSVFileType';
  id: Scalars['ID'];
  file: Scalars['String'];
  dateCreated: Scalars['DateTime'];
  team: TeamType;
};

export type CreateTeam = {
  __typename?: 'CreateTeam';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['String']>;
  team?: Maybe<TeamType>;
};

export type CreateUser = {
  __typename?: 'CreateUser';
  ok?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserType>;
};

export type DashboardType = {
  __typename?: 'DashboardType';
  id: Scalars['String'];
  displayName: Scalars['String'];
  embedUrl: Scalars['String'];
  isReadOnly: Scalars['Boolean'];
};


/**
 * Delete account permanently or make `user.is_active=False`.
 *
 * The behavior is defined on settings.
 * Anyway user refresh tokens are revoked.
 *
 * User must be verified and confirm password.
 */
export type DeleteAccount = {
  __typename?: 'DeleteAccount';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

/** Mutation to delete a spreadsheet, its CSV and all its entries from the database as well as the S3 storage */
export type DeleteSpreadsheetEntirely = {
  __typename?: 'DeleteSpreadsheetEntirely';
  ok?: Maybe<Scalars['Boolean']>;
  spreadsheet?: Maybe<SpreadsheetType>;
};

export type DeleteTeam = {
  __typename?: 'DeleteTeam';
  ok?: Maybe<Scalars['Boolean']>;
  team?: Maybe<TeamType>;
};

export type EntryType = {
  __typename?: 'EntryType';
  id: Scalars['ID'];
  spreadsheet: SpreadsheetType;
  patientId: Scalars['String'];
  kasseName?: Maybe<Scalars['String']>;
  kasseIkNr?: Maybe<Scalars['String']>;
  patIdF?: Maybe<Scalars['String']>;
  patSexWF?: Maybe<Scalars['Float']>;
  patSexMF?: Maybe<Scalars['Float']>;
  patGeschlecht: Scalars['String'];
  patAlter?: Maybe<Scalars['Float']>;
  patPlz?: Maybe<Scalars['Float']>;
  sapvBeginn?: Maybe<Scalars['String']>;
  patGrundkrankheitF1?: Maybe<Scalars['String']>;
  patGrundkrankheitName1?: Maybe<Scalars['String']>;
  patGrundkrankheitF2?: Maybe<Scalars['String']>;
  patGrundkrankheitName2?: Maybe<Scalars['String']>;
  patGrundkrankheitF3?: Maybe<Scalars['String']>;
  patGrundkrankheitName3?: Maybe<Scalars['String']>;
  nebendiagnose1?: Maybe<Scalars['String']>;
  nebendiagnose2?: Maybe<Scalars['String']>;
  nebendiagnose3?: Maybe<Scalars['String']>;
  nebendiagnose4?: Maybe<Scalars['String']>;
  nebendiagnose5?: Maybe<Scalars['String']>;
  massnahmeBeratung?: Maybe<Scalars['Float']>;
  massnahmeKoordination?: Maybe<Scalars['Float']>;
  massnahmeTeilversorgung?: Maybe<Scalars['Float']>;
  massnahmeVollversorgung?: Maybe<Scalars['Float']>;
  erstkontaktPatAng?: Maybe<Scalars['Float']>;
  erstkontaktBeratungsdienst?: Maybe<Scalars['Float']>;
  erstkontaktSonstige?: Maybe<Scalars['Float']>;
  erstkontaktStatHospiz?: Maybe<Scalars['Float']>;
  erstkontaktKrankenhaus?: Maybe<Scalars['Float']>;
  erstkontaktPalliativstation?: Maybe<Scalars['Float']>;
  erstkontaktStatPflege?: Maybe<Scalars['Float']>;
  erstkontaktAmbHospizdienst?: Maybe<Scalars['Float']>;
  erstkontaktAmbPflege?: Maybe<Scalars['Float']>;
  erstkontaktHausarzt?: Maybe<Scalars['Float']>;
  erstkontaktFacharzt?: Maybe<Scalars['Float']>;
  wohnenAmbWohngruppe?: Maybe<Scalars['Float']>;
  wohnenAllein?: Maybe<Scalars['Float']>;
  wohnenCaregiver?: Maybe<Scalars['Float']>;
  wohnenAngehoerigeBelastbar?: Maybe<Scalars['Float']>;
  wohnenAngehoerigeNichtbelastbar?: Maybe<Scalars['Float']>;
  wohnenHospiz?: Maybe<Scalars['Float']>;
  wohnenKurzzeitpflege?: Maybe<Scalars['Float']>;
  wohnenPflege?: Maybe<Scalars['Float']>;
  wohnenSonstig?: Maybe<Scalars['Float']>;
  mp1VersorgungNein?: Maybe<Scalars['Float']>;
  mp1VersorgungIntensivpflege?: Maybe<Scalars['Float']>;
  mp1VersorgungPflegeGrund?: Maybe<Scalars['Float']>;
  mp1VersorgungPflegeBehandlung?: Maybe<Scalars['Float']>;
  mp1VersorgungAmbHospizdienst?: Maybe<Scalars['Float']>;
  mp1Versorgung24hKraft?: Maybe<Scalars['Float']>;
  prognose02Tage?: Maybe<Scalars['Float']>;
  prognose37Tage?: Maybe<Scalars['Float']>;
  prognose14Wochen?: Maybe<Scalars['Float']>;
  prognose512Wochen?: Maybe<Scalars['Float']>;
  prognoseGr12Wochen?: Maybe<Scalars['Float']>;
  karnofsky100?: Maybe<Scalars['Float']>;
  karnofsky90?: Maybe<Scalars['Float']>;
  karnofsky80?: Maybe<Scalars['Float']>;
  karnofsky70?: Maybe<Scalars['Float']>;
  karnofsky60?: Maybe<Scalars['Float']>;
  karnofsky50?: Maybe<Scalars['Float']>;
  karnofsky40?: Maybe<Scalars['Float']>;
  karnofsky30?: Maybe<Scalars['Float']>;
  karnofsky20?: Maybe<Scalars['Float']>;
  karnofsky10?: Maybe<Scalars['Float']>;
  einschaetzungStabil?: Maybe<Scalars['Float']>;
  einschaetzungInstabil?: Maybe<Scalars['Float']>;
  einschaetzungVerschlechternd?: Maybe<Scalars['Float']>;
  einschaetzungSterbend?: Maybe<Scalars['Float']>;
  einschaetzungVerstorben?: Maybe<Scalars['Float']>;
  mp2KasseName?: Maybe<Scalars['String']>;
  mp2KasseIkNr?: Maybe<Scalars['String']>;
  mp2VersorgungNein?: Maybe<Scalars['Float']>;
  mp2VersorgungIntensivpflege?: Maybe<Scalars['Float']>;
  mp2VersorgungPflegeGrund?: Maybe<Scalars['Float']>;
  mp2VersorgungPflegeBehandlung?: Maybe<Scalars['Float']>;
  mp2VersorgungAmbHospizdienst?: Maybe<Scalars['Float']>;
  mp2Versorgung24hKraft?: Maybe<Scalars['Float']>;
  mp2SymptomSchmerzJa?: Maybe<Scalars['Float']>;
  mp2SymptomSchmerzNein?: Maybe<Scalars['Float']>;
  mp2SymptomNeuroJa?: Maybe<Scalars['Float']>;
  mp2SymptomNeuroNein?: Maybe<Scalars['Float']>;
  mp2SymptomWundenJa?: Maybe<Scalars['Float']>;
  mp2SymptomWundenNein?: Maybe<Scalars['Float']>;
  mp2SymptomGastroJa?: Maybe<Scalars['Float']>;
  mp2SymptomGastroNein?: Maybe<Scalars['Float']>;
  mp2SymptomUrogenitalJa?: Maybe<Scalars['Float']>;
  mp2SymptomUrogenitalNein?: Maybe<Scalars['Float']>;
  mp2SymptomRespirJa?: Maybe<Scalars['Float']>;
  mp2SymptomRespirNein?: Maybe<Scalars['Float']>;
  mp2SymptomEthischJa?: Maybe<Scalars['Float']>;
  mp2SymptomEthischNein?: Maybe<Scalars['Float']>;
  mp2SymptomKoordpalliJa?: Maybe<Scalars['Float']>;
  mp2SymptomKoordpalliNein?: Maybe<Scalars['Float']>;
  mp2SymptomBetreuungJa?: Maybe<Scalars['Float']>;
  mp2SymptomBetreuungNein?: Maybe<Scalars['Float']>;
  mp2SymptomLebensverkuerzungJa?: Maybe<Scalars['Float']>;
  mp2SymptomLebensverkuerzungNein?: Maybe<Scalars['Float']>;
  mp2SymptomExistenzJa?: Maybe<Scalars['Float']>;
  mp2SymptomExistenzNein?: Maybe<Scalars['Float']>;
  mp2SymptomKoordprivatJa?: Maybe<Scalars['Float']>;
  mp2SymptomKoordprivatNein?: Maybe<Scalars['Float']>;
  mp2SymptomKurationJa?: Maybe<Scalars['Float']>;
  mp2SymptomKurationNein?: Maybe<Scalars['Float']>;
  mp2SymptomSonstigesText?: Maybe<Scalars['String']>;
  mp2SymptomSonstigesJa?: Maybe<Scalars['Float']>;
  mp2SymptomSonstigesNein?: Maybe<Scalars['Float']>;
  mp2BeendigungSapvAm?: Maybe<Scalars['String']>;
  mp2BeendigungSapvStabilisierung?: Maybe<Scalars['Float']>;
  mp2BeendigungSapvTherapieansatz?: Maybe<Scalars['Float']>;
  mp2BeendigungSapvAblauf?: Maybe<Scalars['Float']>;
  mp2BeendigungSapvVerlegung?: Maybe<Scalars['Float']>;
  mp2BeendigungSapvVerstorben?: Maybe<Scalars['Float']>;
  mp2BeendigungSapvSonstiges?: Maybe<Scalars['Float']>;
  mp2BeurteilungGrenzfall?: Maybe<Scalars['Float']>;
  mp2BeurteilungRoutinefall?: Maybe<Scalars['Float']>;
  mp2BeurteilungProblemfall?: Maybe<Scalars['Float']>;
  mp2EinbindungFrueh?: Maybe<Scalars['Float']>;
  mp2EinbindungRechtzeitig?: Maybe<Scalars['Float']>;
  mp2EinbindungSpaet?: Maybe<Scalars['Float']>;
  mp2MassnahmeBeratung?: Maybe<Scalars['Float']>;
  mp2MassnahmeKoordination?: Maybe<Scalars['Float']>;
  mp2MassnahmeTeilversorgung?: Maybe<Scalars['Float']>;
  mp2MassnahmeVollversorgung?: Maybe<Scalars['Float']>;
  mp2SterbeortZuhause?: Maybe<Scalars['Float']>;
  mp2SterbeortHeim?: Maybe<Scalars['Float']>;
  mp2SterbeortHospiz?: Maybe<Scalars['Float']>;
  mp2SterbeortPalliativstation?: Maybe<Scalars['Float']>;
  mp2SterbeortKrankenhaus?: Maybe<Scalars['Float']>;
  mp2SterbeortSonstige?: Maybe<Scalars['Float']>;
  mp2SterbeortSonstigeText?: Maybe<Scalars['String']>;
  mp2SterbeortWunschJa?: Maybe<Scalars['Float']>;
  mp2SterbeortWunschNein?: Maybe<Scalars['Float']>;
  mp2SterbeortWunschUnbekannt?: Maybe<Scalars['Float']>;
  mp2TageBereitschaft?: Maybe<Scalars['Float']>;
  mp2HausbesuchPcteam?: Maybe<Scalars['Float']>;
  mp2IntermittierungTage?: Maybe<Scalars['Float']>;
  mp2Notarzteinsaetze0?: Maybe<Scalars['Float']>;
  mp2Notarzteinsaetze1?: Maybe<Scalars['Float']>;
  mp2Notarzteinsaetze2?: Maybe<Scalars['Float']>;
  mp2Notarzteinsaetze3?: Maybe<Scalars['Float']>;
  mp2Notarzteinsaetze4?: Maybe<Scalars['Float']>;
  mp2NotarzteinsaetzeGr5?: Maybe<Scalars['Float']>;
  mp2KrankenhauseinweisungenKeine?: Maybe<Scalars['Float']>;
  mp2Krankenhauseinweisungen13?: Maybe<Scalars['Float']>;
  mp2KrankenhauseinweisungenGr3?: Maybe<Scalars['Float']>;
  mp2ErsteEinweisungWegen1?: Maybe<Scalars['Float']>;
  mp2ErsteEinweisungWegen2?: Maybe<Scalars['Float']>;
  mp2ErsteEinweisungWegen3?: Maybe<Scalars['Float']>;
  mp2ErsteEinweisungWegen4?: Maybe<Scalars['Float']>;
  mp2ErsteEinweisungWegen5?: Maybe<Scalars['Float']>;
  mp2ErsteEinweisungWegen6?: Maybe<Scalars['Float']>;
  mp2ZweiteEinweisungWegen1?: Maybe<Scalars['Float']>;
  mp2ZweiteEinweisungWegen2?: Maybe<Scalars['Float']>;
  mp2ZweiteEinweisungWegen3?: Maybe<Scalars['Float']>;
  mp2ZweiteEinweisungWegen4?: Maybe<Scalars['Float']>;
  mp2ZweiteEinweisungWegen5?: Maybe<Scalars['Float']>;
  mp2ZweiteEinweisungWegen6?: Maybe<Scalars['Float']>;
  mp2DritteEinweisungWegen1?: Maybe<Scalars['Float']>;
  mp2DritteEinweisungWegen2?: Maybe<Scalars['Float']>;
  mp2DritteEinweisungWegen3?: Maybe<Scalars['Float']>;
  mp2DritteEinweisungWegen4?: Maybe<Scalars['Float']>;
  mp2DritteEinweisungWegen5?: Maybe<Scalars['Float']>;
  mp2DritteEinweisungWegen6?: Maybe<Scalars['Float']>;
};


export type FailedValidationResultsType = {
  __typename?: 'FailedValidationResultsType';
  id: Scalars['ID'];
  spreadsheet: SpreadsheetType;
  numRowsReceived: Scalars['Int'];
  numRowsAccepted: Scalars['Int'];
  numRowsRejected: Scalars['Int'];
  dateCreated: Scalars['DateTime'];
  feedbacklogSet: Array<FeedbackLogType>;
};

export type FeedbackLogType = {
  __typename?: 'FeedbackLogType';
  id: Scalars['ID'];
  patientId: Scalars['String'];
  validationResults: FailedValidationResultsType;
  params: Scalars['String'];
  description: Scalars['String'];
  sapvBeginn?: Maybe<Scalars['String']>;
};


export type GetVisualizationUrl = {
  __typename?: 'GetVisualizationUrl';
  ok?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type Logout = {
  __typename?: 'Logout';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Register user with fields defined in the settings.
   *
   * If the email field of the user model is part of the
   * registration fields (default), check if there is
   * no user with that email or as a secondary email.
   *
   * If it exists, it does not register the user,
   * even if the email field is not defined as unique
   * (default of the default django user model).
   *
   * When creating the user, it also creates a `UserStatus`
   * related to that user, making it possible to track
   * if the user is archived, verified and has a secondary
   * email.
   *
   * Send account verification email.
   *
   * If allowed to not verified users login, return token.
   */
  register?: Maybe<Register>;
  /**
   * Verify user account.
   *
   * Receive the token that was sent by email.
   * If the token is valid, make the user verified
   * by making the `user.status.verified` field true.
   */
  verifyAccount?: Maybe<VerifyAccount>;
  /**
   * Sends activation email.
   *
   * It is called resend because theoretically
   * the first activation email was sent when
   * the user registered.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  resendActivationEmail?: Maybe<ResendActivationEmail>;
  /**
   * Send password reset email.
   *
   * For non verified users, send an activation
   * email instead.
   *
   * Accepts both primary and secondary email.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmail>;
  /**
   * Change user password without old password.
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, update
   * user password and in case of using refresh
   * tokens, revoke all of them.
   */
  passwordReset?: Maybe<PasswordReset>;
  /**
   * Change account password when user knows the old password.
   *
   * A new token and refresh token are sent. User must be verified.
   */
  passwordChange?: Maybe<PasswordChange>;
  /**
   * Archive account and revoke refresh tokens.
   *
   * User must be verified and confirm password.
   */
  archiveAccount?: Maybe<ArchiveAccount>;
  /**
   * Delete account permanently or make `user.is_active=False`.
   *
   * The behavior is defined on settings.
   * Anyway user refresh tokens are revoked.
   *
   * User must be verified and confirm password.
   */
  deleteAccount?: Maybe<DeleteAccount>;
  /**
   * Update user model fields, defined on settings.
   *
   * User must be verified.
   */
  updateAccount?: Maybe<UpdateAccount>;
  /**
   * Send activation to secondary email.
   *
   * User must be verified and confirm password.
   */
  sendSecondaryEmailActivation?: Maybe<SendSecondaryEmailActivation>;
  /**
   * Verify user secondary email.
   *
   * Receive the token that was sent by email.
   * User is already verified when using this mutation.
   *
   * If the token is valid, add the secondary email
   * to `user.status.secondary_email` field.
   *
   * Note that until the secondary email is verified,
   * it has not been saved anywhere beyond the token,
   * so it can still be used to create a new account.
   * After being verified, it will no longer be available.
   */
  verifySecondaryEmail?: Maybe<VerifySecondaryEmail>;
  /**
   * Swap between primary and secondary emails.
   *
   * Require password confirmation.
   */
  swapEmails?: Maybe<SwapEmails>;
  /**
   * Obtain JSON web token for given user.
   *
   * Allow to perform login with different fields,
   * and secondary email if set. The fields are
   * defined on settings.
   *
   * Not verified users can login by default. This
   * can be changes on settings.
   *
   * If user is archived, make it unarchive and
   * return `unarchiving=True` on output.
   */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  verifyToken?: Maybe<VerifyToken>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  refreshToken?: Maybe<RefreshToken>;
  revokeToken?: Maybe<RevokeToken>;
  createUser?: Maybe<CreateUser>;
  logout?: Maybe<Logout>;
  createTeam?: Maybe<CreateTeam>;
  updateTeam?: Maybe<UpdateTeam>;
  updateToken?: Maybe<UpdateToken>;
  deleteTeam?: Maybe<DeleteTeam>;
  /** Mutation to delete a spreadsheet, its CSV and all its entries from the database as well as the S3 storage */
  deleteSpreadsheetEntirely?: Maybe<DeleteSpreadsheetEntirely>;
  getVisualizationUrl?: Maybe<GetVisualizationUrl>;
  authenticateToken?: Maybe<AuthenticateToken>;
  uploadFiles?: Maybe<UploadFiles>;
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  username: Scalars['String'];
  password1: Scalars['String'];
  password2: Scalars['String'];
};


export type MutationVerifyAccountArgs = {
  token: Scalars['String'];
};


export type MutationResendActivationEmailArgs = {
  email: Scalars['String'];
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationPasswordResetArgs = {
  token: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
};


export type MutationPasswordChangeArgs = {
  oldPassword: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
};


export type MutationArchiveAccountArgs = {
  password: Scalars['String'];
};


export type MutationDeleteAccountArgs = {
  password: Scalars['String'];
};


export type MutationUpdateAccountArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type MutationSendSecondaryEmailActivationArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifySecondaryEmailArgs = {
  token: Scalars['String'];
};


export type MutationSwapEmailsArgs = {
  password: Scalars['String'];
};


export type MutationTokenAuthArgs = {
  password: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type MutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};


export type MutationRevokeTokenArgs = {
  id: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateTeamArgs = {
  input: TeamInput;
};


export type MutationUpdateTeamArgs = {
  contactEmail?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};


export type MutationUpdateTokenArgs = {
  id: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};


export type MutationDeleteTeamArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSpreadsheetEntirelyArgs = {
  id: Scalars['Int'];
};


export type MutationGetVisualizationUrlArgs = {
  input: TokenInput;
};


export type MutationAuthenticateTokenArgs = {
  input: TokenInput;
};


export type MutationUploadFilesArgs = {
  fileIn: Scalars['Upload'];
  teamId: Scalars['String'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/**
 * Obtain JSON web token for given user.
 *
 * Allow to perform login with different fields,
 * and secondary email if set. The fields are
 * defined on settings.
 *
 * Not verified users can login by default. This
 * can be changes on settings.
 *
 * If user is archived, make it unarchive and
 * return `unarchiving=True` on output.
 */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  user?: Maybe<UserNode>;
  unarchiving?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

/**
 * Change account password when user knows the old password.
 *
 * A new token and refresh token are sent. User must be verified.
 */
export type PasswordChange = {
  __typename?: 'PasswordChange';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/**
 * Change user password without old password.
 *
 * Receive the token that was sent by email.
 *
 * If token and new passwords are valid, update
 * user password and in case of using refresh
 * tokens, revoke all of them.
 */
export type PasswordReset = {
  __typename?: 'PasswordReset';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<UserType>;
  user?: Maybe<UserType>;
  users?: Maybe<Array<Maybe<UserType>>>;
  team?: Maybe<TeamType>;
  spreadsheet?: Maybe<SpreadsheetType>;
  reportPdf?: Maybe<ReportPdfType>;
  csv?: Maybe<CsvFileType>;
  dashboard?: Maybe<DashboardType>;
  report?: Maybe<ReportType>;
  entry?: Maybe<EntryType>;
  teams?: Maybe<Array<Maybe<TeamType>>>;
  spreadsheets?: Maybe<Array<Maybe<SpreadsheetType>>>;
  reportPdfs?: Maybe<Array<Maybe<ReportPdfType>>>;
  csvs?: Maybe<Array<Maybe<CsvFileType>>>;
  dashboards?: Maybe<Array<Maybe<DashboardType>>>;
  reports?: Maybe<Array<Maybe<ReportType>>>;
  feedbackLogs?: Maybe<Array<Maybe<FeedbackLogType>>>;
  failedValidationResults?: Maybe<FailedValidationResultsType>;
};


export type QueryUserArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryTeamArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QuerySpreadsheetArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryReportPdfArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCsvArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryDashboardArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryReportArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryEntryArgs = {
  spreadsheetId?: Maybe<Scalars['String']>;
};


export type QueryFailedValidationResultsArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RefreshToken = {
  __typename?: 'RefreshToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

/**
 * Register user with fields defined in the settings.
 *
 * If the email field of the user model is part of the
 * registration fields (default), check if there is
 * no user with that email or as a secondary email.
 *
 * If it exists, it does not register the user,
 * even if the email field is not defined as unique
 * (default of the default django user model).
 *
 * When creating the user, it also creates a `UserStatus`
 * related to that user, making it possible to track
 * if the user is archived, verified and has a secondary
 * email.
 *
 * Send account verification email.
 *
 * If allowed to not verified users login, return token.
 */
export type Register = {
  __typename?: 'Register';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type ReportPdfType = {
  __typename?: 'ReportPDFType';
  id: Scalars['String'];
  resourceFileExtension: Scalars['String'];
  reportName: Scalars['String'];
  createdDateTime: Scalars['DateTime'];
  resourceLocation: Scalars['String'];
  status: Scalars['String'];
  lastActionDateTime: Scalars['DateTime'];
};

export type ReportType = {
  __typename?: 'ReportType';
  id: Scalars['String'];
  datasetId: Scalars['String'];
  name: Scalars['String'];
  webUrl: Scalars['String'];
  embedUrl: Scalars['String'];
  accessToken: Scalars['String'];
};

/**
 * Sends activation email.
 *
 * It is called resend because theoretically
 * the first activation email was sent when
 * the user registered.
 *
 * If there is no user with the requested email,
 * a successful response is returned.
 */
export type ResendActivationEmail = {
  __typename?: 'ResendActivationEmail';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type RevokeToken = {
  __typename?: 'RevokeToken';
  ok?: Maybe<Scalars['Boolean']>;
};

/**
 * Send password reset email.
 *
 * For non verified users, send an activation
 * email instead.
 *
 * Accepts both primary and secondary email.
 *
 * If there is no user with the requested email,
 * a successful response is returned.
 */
export type SendPasswordResetEmail = {
  __typename?: 'SendPasswordResetEmail';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

/**
 * Send activation to secondary email.
 *
 * User must be verified and confirm password.
 */
export type SendSecondaryEmailActivation = {
  __typename?: 'SendSecondaryEmailActivation';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type SpreadsheetType = {
  __typename?: 'SpreadsheetType';
  id: Scalars['ID'];
  filename: Scalars['String'];
  fileId: Scalars['String'];
  dateUploaded: Scalars['DateTime'];
  team: TeamType;
  entrySet: Array<EntryType>;
  failedvalidationresultsSet: Array<FailedValidationResultsType>;
  sasUri?: Maybe<Scalars['String']>;
  pdfUri?: Maybe<Scalars['String']>;
};

/**
 * Swap between primary and secondary emails.
 *
 * Require password confirmation.
 */
export type SwapEmails = {
  __typename?: 'SwapEmails';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type TeamInput = {
  name?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  contactNameFull?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type TeamType = {
  __typename?: 'TeamType';
  id: Scalars['UUID'];
  name: Scalars['String'];
  contactEmail: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  contactNameFull: Scalars['String'];
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  token?: Maybe<Scalars['String']>;
  csvfileSet: Array<CsvFileType>;
  spreadsheetSet: Array<SpreadsheetType>;
};

export type TokenInput = {
  token?: Maybe<Scalars['String']>;
};


/**
 * Update user model fields, defined on settings.
 *
 * User must be verified.
 */
export type UpdateAccount = {
  __typename?: 'UpdateAccount';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type UpdateTeam = {
  __typename?: 'UpdateTeam';
  ok?: Maybe<Scalars['Boolean']>;
  team?: Maybe<TeamType>;
};

export type UpdateToken = {
  __typename?: 'UpdateToken';
  ok?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};


export type UploadFiles = {
  __typename?: 'UploadFiles';
  fileIn?: Maybe<Scalars['Upload']>;
  teamId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  patientId?: Maybe<Scalars['String']>;
  params?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  sapvBeginn?: Maybe<Scalars['String']>;
  /** <graphene.types.scalars.String object at 0x7fc781427ed0> */
  logs?: Maybe<Array<Maybe<FeedbackLogType>>>;
  new?: Maybe<Scalars['Int']>;
  received?: Maybe<Scalars['Int']>;
  accepted?: Maybe<Scalars['Int']>;
  rejected?: Maybe<Scalars['Int']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  prevUp?: Maybe<Scalars['Boolean']>;
  pdfUploadUri?: Maybe<Scalars['String']>;
};


export type UploadFilesLogsArgs = {
  patientId?: Maybe<Scalars['String']>;
  params?: Maybe<Array<Maybe<Scalars['String']>>>;
  sapvBeginn?: Maybe<Scalars['String']>;
};

export type UserInput = {
  id?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  password: Scalars['String'];
  isStaff?: Maybe<Scalars['Boolean']>;
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  email: Scalars['String'];
  pk?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  secondaryEmail?: Maybe<Scalars['String']>;
};

export type UserType = {
  __typename?: 'UserType';
  password: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  id: Scalars['UUID'];
  email: Scalars['String'];
};

/**
 * Verify user account.
 *
 * Receive the token that was sent by email.
 * If the token is valid, make the user verified
 * by making the `user.status.verified` field true.
 */
export type VerifyAccount = {
  __typename?: 'VerifyAccount';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

/**
 * Verify user secondary email.
 *
 * Receive the token that was sent by email.
 * User is already verified when using this mutation.
 *
 * If the token is valid, add the secondary email
 * to `user.status.secondary_email` field.
 *
 * Note that until the secondary email is verified,
 * it has not been saved anywhere beyond the token,
 * so it can still be used to create a new account.
 * After being verified, it will no longer be available.
 */
export type VerifySecondaryEmail = {
  __typename?: 'VerifySecondaryEmail';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type VerifyToken = {
  __typename?: 'VerifyToken';
  payload: Scalars['GenericScalar'];
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type ReportFragment = (
  { __typename?: 'ReportType' }
  & Pick<ReportType, 'id' | 'datasetId' | 'name' | 'webUrl' | 'embedUrl' | 'accessToken'>
);

export type SpreadsheetFragment = (
  { __typename?: 'SpreadsheetType' }
  & Pick<SpreadsheetType, 'id' | 'sasUri' | 'pdfUri' | 'filename' | 'dateUploaded'>
);

export type TeamFragment = (
  { __typename?: 'TeamType' }
  & Pick<TeamType, 'id' | 'name' | 'token' | 'lastName' | 'firstName' | 'dateCreated' | 'dateModified' | 'contactEmail' | 'contactNameFull'>
  & { spreadsheetSet: Array<(
    { __typename?: 'SpreadsheetType' }
    & Pick<SpreadsheetType, 'id' | 'filename' | 'sasUri' | 'pdfUri' | 'dateUploaded'>
  )> }
);

export type UserFragment = (
  { __typename?: 'UserType' }
  & Pick<UserType, 'id' | 'lastLogin' | 'username' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'dateJoined' | 'email'>
);

export type CreateTeamMutationVariables = Exact<{
  input: TeamInput;
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam?: Maybe<(
    { __typename?: 'CreateTeam' }
    & Pick<CreateTeam, 'ok'>
    & { team?: Maybe<(
      { __typename?: 'TeamType' }
      & Pick<TeamType, 'id' | 'name' | 'dateCreated'>
    )> }
  )> }
);

export type GetVisualizationUrlMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetVisualizationUrlMutation = (
  { __typename?: 'Mutation' }
  & { getVisualizationUrl?: Maybe<(
    { __typename?: 'GetVisualizationUrl' }
    & Pick<GetVisualizationUrl, 'ok' | 'url'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { tokenAuth?: Maybe<(
    { __typename?: 'ObtainJSONWebToken' }
    & Pick<ObtainJsonWebToken, 'token' | 'success' | 'refreshToken' | 'errors'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'email' | 'username' | 'isStaff' | 'lastLogin'>
    )> }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'Logout' }
    & Pick<Logout, 'ok'>
  )> }
);

export type RemoveSpreadsheetMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveSpreadsheetMutation = (
  { __typename?: 'Mutation' }
  & { deleteSpreadsheetEntirely?: Maybe<(
    { __typename?: 'DeleteSpreadsheetEntirely' }
    & Pick<DeleteSpreadsheetEntirely, 'ok'>
  )> }
);

export type RemoveTeamMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveTeamMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeam?: Maybe<(
    { __typename?: 'DeleteTeam' }
    & Pick<DeleteTeam, 'ok'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { passwordReset?: Maybe<(
    { __typename?: 'PasswordReset' }
    & Pick<PasswordReset, 'success' | 'errors'>
  )> }
);

export type RevokeTokenMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RevokeTokenMutation = (
  { __typename?: 'Mutation' }
  & { revokeToken?: Maybe<(
    { __typename?: 'RevokeToken' }
    & Pick<RevokeToken, 'ok'>
  )> }
);

export type SendResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendPasswordResetEmail?: Maybe<(
    { __typename?: 'SendPasswordResetEmail' }
    & Pick<SendPasswordResetEmail, 'success' | 'errors'>
  )> }
);

export type TeamFromTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type TeamFromTokenMutation = (
  { __typename?: 'Mutation' }
  & { authenticateToken?: Maybe<(
    { __typename?: 'AuthenticateToken' }
    & Pick<AuthenticateToken, 'ok'>
    & { team?: Maybe<(
      { __typename?: 'TeamType' }
      & Pick<TeamType, 'id' | 'name'>
      & { spreadsheetSet: Array<(
        { __typename?: 'SpreadsheetType' }
        & Pick<SpreadsheetType, 'id' | 'filename' | 'sasUri' | 'dateUploaded'>
      )> }
    )> }
  )> }
);

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
}>;


export type UpdateTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateTeam?: Maybe<(
    { __typename?: 'UpdateTeam' }
    & Pick<UpdateTeam, 'ok'>
  )> }
);

export type UpdateTokenMutationVariables = Exact<{
  id: Scalars['String'];
  token: Scalars['String'];
}>;


export type UpdateTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateToken?: Maybe<(
    { __typename?: 'UpdateToken' }
    & Pick<UpdateToken, 'ok'>
  )> }
);

export type UploadFilesMutationVariables = Exact<{
  fileIn: Scalars['Upload'];
  teamId: Scalars['String'];
}>;


export type UploadFilesMutation = (
  { __typename?: 'Mutation' }
  & { uploadFiles?: Maybe<(
    { __typename?: 'UploadFiles' }
    & Pick<UploadFiles, 'success' | 'new' | 'received' | 'accepted' | 'rejected' | 'dateCreated' | 'prevUp' | 'pdfUploadUri'>
    & { logs?: Maybe<Array<Maybe<(
      { __typename?: 'FeedbackLogType' }
      & Pick<FeedbackLogType, 'patientId' | 'params' | 'description' | 'sapvBeginn'>
    )>>> }
  )> }
);

export type VerifyTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyTokenMutation = (
  { __typename?: 'Mutation' }
  & { authenticateToken?: Maybe<(
    { __typename?: 'AuthenticateToken' }
    & Pick<AuthenticateToken, 'ok'>
    & { team?: Maybe<(
      { __typename?: 'TeamType' }
      & Pick<TeamType, 'id' | 'name' | 'contactEmail' | 'contactNameFull'>
    )> }
  )> }
);

export type LatestSpreadsheetsQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestSpreadsheetsQuery = (
  { __typename?: 'Query' }
  & { spreadsheets?: Maybe<Array<Maybe<(
    { __typename?: 'SpreadsheetType' }
    & Pick<SpreadsheetType, 'id' | 'filename' | 'dateUploaded'>
    & { team: (
      { __typename?: 'TeamType' }
      & Pick<TeamType, 'id' | 'name'>
    ) }
  )>>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'email' | 'username' | 'isStaff' | 'lastLogin'>
  )> }
);

export type ReportQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { report?: Maybe<(
    { __typename?: 'ReportType' }
    & ReportFragment
  )> }
);

export type SpreadsheetQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SpreadsheetQuery = (
  { __typename?: 'Query' }
  & { spreadsheet?: Maybe<(
    { __typename?: 'SpreadsheetType' }
    & Pick<SpreadsheetType, 'id' | 'filename' | 'sasUri' | 'pdfUri' | 'dateUploaded'>
    & { failedvalidationresultsSet: Array<(
      { __typename?: 'FailedValidationResultsType' }
      & Pick<FailedValidationResultsType, 'id' | 'numRowsReceived' | 'numRowsAccepted' | 'numRowsRejected' | 'dateCreated'>
      & { feedbacklogSet: Array<(
        { __typename?: 'FeedbackLogType' }
        & Pick<FeedbackLogType, 'id' | 'patientId' | 'params' | 'description' | 'sapvBeginn'>
      )> }
    )> }
  )> }
);

export type TeamQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TeamQuery = (
  { __typename?: 'Query' }
  & { team?: Maybe<(
    { __typename?: 'TeamType' }
    & Pick<TeamType, 'id' | 'name' | 'token' | 'lastName' | 'firstName' | 'dateCreated' | 'dateModified' | 'contactEmail' | 'contactNameFull'>
    & { spreadsheetSet: Array<(
      { __typename?: 'SpreadsheetType' }
      & Pick<SpreadsheetType, 'id' | 'sasUri' | 'pdfUri' | 'filename' | 'dateUploaded'>
    )> }
  )> }
);

export type TeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamsQuery = (
  { __typename?: 'Query' }
  & { teams?: Maybe<Array<Maybe<(
    { __typename?: 'TeamType' }
    & Pick<TeamType, 'id' | 'name' | 'dateCreated'>
  )>>> }
);

export const ReportFragmentDoc = gql`
    fragment Report on ReportType {
  id
  datasetId
  name
  webUrl
  embedUrl
  accessToken
}
    `;
export const SpreadsheetFragmentDoc = gql`
    fragment Spreadsheet on SpreadsheetType {
  id
  sasUri
  pdfUri
  filename
  dateUploaded
}
    `;
export const TeamFragmentDoc = gql`
    fragment Team on TeamType {
  id
  name
  token
  lastName
  firstName
  dateCreated
  dateModified
  contactEmail
  contactNameFull
  spreadsheetSet {
    id
    filename
    sasUri
    pdfUri
    dateUploaded
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UserType {
  id
  lastLogin
  username
  firstName
  lastName
  isStaff
  isActive
  dateJoined
  email
}
    `;
export const CreateTeamDocument = gql`
    mutation CreateTeam($input: TeamInput!) {
  createTeam(input: $input) {
    ok
    team {
      id
      name
      dateCreated
    }
  }
}
    `;

export function useCreateTeamMutation() {
  return Urql.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument);
};
export const GetVisualizationUrlDocument = gql`
    mutation GetVisualizationUrl($token: String!) {
  getVisualizationUrl(input: {token: $token}) {
    ok
    url
  }
}
    `;

export function useGetVisualizationUrlMutation() {
  return Urql.useMutation<GetVisualizationUrlMutation, GetVisualizationUrlMutationVariables>(GetVisualizationUrlDocument);
};
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  tokenAuth(email: $email, password: $password) {
    token
    success
    refreshToken
    errors
    user {
      id
      email
      username
      isStaff
      lastLogin
    }
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    ok
  }
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const RemoveSpreadsheetDocument = gql`
    mutation RemoveSpreadsheet($id: Int!) {
  deleteSpreadsheetEntirely(id: $id) {
    ok
  }
}
    `;

export function useRemoveSpreadsheetMutation() {
  return Urql.useMutation<RemoveSpreadsheetMutation, RemoveSpreadsheetMutationVariables>(RemoveSpreadsheetDocument);
};
export const RemoveTeamDocument = gql`
    mutation RemoveTeam($id: String!) {
  deleteTeam(id: $id) {
    ok
  }
}
    `;

export function useRemoveTeamMutation() {
  return Urql.useMutation<RemoveTeamMutation, RemoveTeamMutationVariables>(RemoveTeamDocument);
};
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $newPassword1: String!, $newPassword2: String!) {
  passwordReset(
    token: $token
    newPassword1: $newPassword1
    newPassword2: $newPassword2
  ) {
    success
    errors
  }
}
    `;

export function useResetPasswordMutation() {
  return Urql.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument);
};
export const RevokeTokenDocument = gql`
    mutation RevokeToken($id: String!) {
  revokeToken(id: $id) {
    ok
  }
}
    `;

export function useRevokeTokenMutation() {
  return Urql.useMutation<RevokeTokenMutation, RevokeTokenMutationVariables>(RevokeTokenDocument);
};
export const SendResetEmailDocument = gql`
    mutation SendResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email) {
    success
    errors
  }
}
    `;

export function useSendResetEmailMutation() {
  return Urql.useMutation<SendResetEmailMutation, SendResetEmailMutationVariables>(SendResetEmailDocument);
};
export const TeamFromTokenDocument = gql`
    mutation TeamFromToken($token: String!) {
  authenticateToken(input: {token: $token}) {
    ok
    team {
      id
      name
      spreadsheetSet {
        id
        filename
        sasUri
        dateUploaded
      }
    }
  }
}
    `;

export function useTeamFromTokenMutation() {
  return Urql.useMutation<TeamFromTokenMutation, TeamFromTokenMutationVariables>(TeamFromTokenDocument);
};
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($id: String!, $name: String, $email: String, $firstName: String, $lastName: String) {
  updateTeam(
    id: $id
    name: $name
    contactEmail: $email
    firstName: $firstName
    lastName: $lastName
  ) {
    ok
  }
}
    `;

export function useUpdateTeamMutation() {
  return Urql.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument);
};
export const UpdateTokenDocument = gql`
    mutation UpdateToken($id: String!, $token: String!) {
  updateToken(id: $id, token: $token) {
    ok
  }
}
    `;

export function useUpdateTokenMutation() {
  return Urql.useMutation<UpdateTokenMutation, UpdateTokenMutationVariables>(UpdateTokenDocument);
};
export const UploadFilesDocument = gql`
    mutation UploadFiles($fileIn: Upload!, $teamId: String!) {
  uploadFiles(fileIn: $fileIn, teamId: $teamId) {
    success
    new
    received
    accepted
    rejected
    dateCreated
    prevUp
    pdfUploadUri
    logs {
      patientId
      params
      description
      sapvBeginn
    }
  }
}
    `;

export function useUploadFilesMutation() {
  return Urql.useMutation<UploadFilesMutation, UploadFilesMutationVariables>(UploadFilesDocument);
};
export const VerifyTokenDocument = gql`
    mutation VerifyToken($token: String!) {
  authenticateToken(input: {token: $token}) {
    ok
    team {
      id
      name
      contactEmail
      contactNameFull
    }
  }
}
    `;

export function useVerifyTokenMutation() {
  return Urql.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument);
};
export const LatestSpreadsheetsDocument = gql`
    query LatestSpreadsheets {
  spreadsheets {
    id
    filename
    dateUploaded
    team {
      id
      name
    }
  }
}
    `;

export function useLatestSpreadsheetsQuery(options: Omit<Urql.UseQueryArgs<LatestSpreadsheetsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<LatestSpreadsheetsQuery>({ query: LatestSpreadsheetsDocument, ...options });
};
export const MeDocument = gql`
    query Me {
  me {
    id
    email
    username
    isStaff
    lastLogin
  }
}
    `;

export function useMeQuery(options: Omit<Urql.UseQueryArgs<MeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MeQuery>({ query: MeDocument, ...options });
};
export const ReportDocument = gql`
    query Report($id: String!) {
  report(id: $id) {
    ...Report
  }
}
    ${ReportFragmentDoc}`;

export function useReportQuery(options: Omit<Urql.UseQueryArgs<ReportQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ReportQuery>({ query: ReportDocument, ...options });
};
export const SpreadsheetDocument = gql`
    query Spreadsheet($id: Int!) {
  spreadsheet(id: $id) {
    id
    filename
    sasUri
    pdfUri
    dateUploaded
    failedvalidationresultsSet {
      id
      numRowsReceived
      numRowsAccepted
      numRowsRejected
      dateCreated
      feedbacklogSet {
        id
        patientId
        params
        description
        sapvBeginn
      }
    }
  }
}
    `;

export function useSpreadsheetQuery(options: Omit<Urql.UseQueryArgs<SpreadsheetQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SpreadsheetQuery>({ query: SpreadsheetDocument, ...options });
};
export const TeamDocument = gql`
    query Team($id: String!) {
  team(id: $id) {
    id
    name
    token
    lastName
    firstName
    dateCreated
    dateModified
    contactEmail
    contactNameFull
    spreadsheetSet {
      id
      sasUri
      pdfUri
      filename
      dateUploaded
    }
  }
}
    `;

export function useTeamQuery(options: Omit<Urql.UseQueryArgs<TeamQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TeamQuery>({ query: TeamDocument, ...options });
};
export const TeamsDocument = gql`
    query Teams {
  teams {
    id
    name
    dateCreated
  }
}
    `;

export function useTeamsQuery(options: Omit<Urql.UseQueryArgs<TeamsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TeamsQuery>({ query: TeamsDocument, ...options });
};