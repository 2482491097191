import { navigate } from "@reach/router";
import React, { createContext, useContext, useMemo } from "react";

// Utils
import { SnackbarContext } from "./snackbar";
import { removeTokens, setToken } from "../core/token";
import {
  useLoginMutation,
  useLogoutMutation,
  useMeQuery,
} from "../generated/graphql";

// types
type LoginData = {
  email: string;
  password: string;
};
type User = {
  id: string;
  email: string;
  username: string;
  isStaff: boolean;
  lastLogin: string;
};
type Context = {
  user?: User;
  loading?: boolean;
  hasAccess?: boolean;
  loginLoading?: boolean;
  logoutLoading?: boolean;
  login: (data: LoginData) => Promise<void>;
  register: (data: any) => Promise<void>;
  getUser: () => Promise<void>;
  logout: () => Promise<void>;
};

// initial
const initialContext: Context = {
  login: _ => Promise.resolve(),
  register: _ => Promise.resolve(),
  getUser: () => Promise.resolve(),
  logout: () => Promise.resolve(),
};

type Props = {
  children: any;
};
export const UserContext = createContext<Context>(initialContext);
export function UserProvider({ children }: Props) {
  // state
  const { show } = useContext(SnackbarContext);

  // Queries and mutations
  const [{ data: meData, fetching: loading }] = useMeQuery();
  const [{ fetching: logoutLoading }, logout] = useLogoutMutation();
  const [{ fetching: loginLoading }, loginMutation] = useLoginMutation();

  // Data
  const user = useMemo(() => {
    if (!(meData && meData.me)) return undefined;
    return meData.me as User;
  }, [meData]);

  const hasAccess = useMemo(() => user && user.isStaff, [user]);
  // const hasAccess = true;

  // Context
  const value: Context = {
    user,
    loading,
    hasAccess,
    loginLoading,
    logoutLoading,
    login: async (loginData: LoginData) => {
      const { email, password } = loginData;
      const { data, error } = await loginMutation({ email, password });
      console.log(data);

      if (!data?.tokenAuth?.success)
        return show(error?.message || "Ungültige Anmeldeinformationen");

      if (!data.tokenAuth.token) return;
      setToken(data.tokenAuth.token);

      navigate("/admin/dashboard");
    },
    register: async _ => Promise.resolve(),
    getUser: async () => Promise.resolve(),
    logout: async () => {
      await logout();
      removeTokens();
      show("Logged out");
      navigate("/admin/login");
    },
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
