const key = "sapv_token";
const refresh_key = "sapv_refresh_token";

export const getToken = () => localStorage.getItem(key);
export const setToken = (token: string) => localStorage.setItem(key, token);
export const removeToken = () => localStorage.removeItem(key);

export const getRefreshToken = () => localStorage.getItem(refresh_key);
export const setRefreshToken = (token: string) =>
  localStorage.setItem(refresh_key, token);
export const removeRefreshToken = () => localStorage.removeItem(refresh_key);

export const setTokens = (access: string, refresh: string) => {
  setToken(access);
  setRefreshToken(refresh);
};

export const getTokens = () => ({
  accessToken: getToken(),
  refreshToken: getRefreshToken(),
});

export const removeTokens = () => {
  removeToken();
  removeRefreshToken();
};
