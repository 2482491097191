import React from "react";
import { Grommet, ThemeType } from "grommet";

// Components
import Navigation from "./app/UI/Navigation";

// Providers
import { ApiProvider } from "./app/core/api";
import { SnackbarProvider } from "./app/store/snackbar";
import { TeamsProvider } from "./app/store/teams";
import { UserProvider } from "./app/store/user";

const theme: ThemeType = {
  global: {
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px",
    },
    colors: {
      primary: "#1b4660",
      secondary: "#50b1f9",
      white: "#ffffff",
      background: "#eeeeee",
      black: "#000000",
      primaryVariant: "#d3f476",
    },
    focus: {
      border: { color: "primary" },
      outline: { size: "0px" },
      shadow: { size: "0px" },
    },
  },
  card: {
    container: {
      elevation: "0px",
    },
  },
};

export default function App() {
  return (
    <Grommet theme={theme}>
      <ApiProvider>
        <SnackbarProvider>
          <UserProvider>
            <TeamsProvider>
              <Navigation />
            </TeamsProvider>
          </UserProvider>
        </SnackbarProvider>
      </ApiProvider>
    </Grommet>
  );
}
