import React from "react";
import { pipe, tap } from "wonka";
import { navigate } from "@reach/router";
import { Cache, cacheExchange } from "@urql/exchange-graphcache";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { createClient as createWSClient } from "graphql-ws";
import {
  gql,
  Provider,
  Exchange,
  createClient,
  dedupExchange,
  subscriptionExchange,
} from "urql";

// utils
import { getToken } from "./token";
import { updateCache } from "../utils/updateCache";
import {
  MeQuery,
  MeDocument,
  TeamsQuery,
  TeamsDocument,
  LoginMutation,
  CreateTeamMutation,
  RemoveTeamMutation,
  RemoveTeamMutationVariables,
  RevokeTokenMutationVariables,
  UpdateTeamMutationVariables,
} from "../generated/graphql";

// calculate graphql path
/*eslint no-restricted-globals: ["off"]*/
const isLocalhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1';
const url = `http${isLocalhost ? '' : 's'}://${location.host}/graphql/`;

// websocket client (for gql subs) + exchange
const wsClient = createWSClient({
  url: `ws${isLocalhost ? '' : 's'}://${location.host}/graphql/`,
});
const subExchange = subscriptionExchange({
  forwardSubscription: op => ({
    subscribe: sink => {
      const unsubscribe = wsClient.subscribe(op, sink);
      return { unsubscribe };
    },
  }),
});

// Custom cache exchange functions
const invalidateTeams = (cache: Cache) => {
  const allFields = cache.inspectFields("Query");
  const loadedTeams = allFields.filter(x => x.fieldName === "team");
  cache.invalidate("Query", "teams");
  loadedTeams.forEach(x =>
    cache.invalidate("Query", "team", x.arguments || {}),
  );
};

const customCacheExchange = cacheExchange({
  updates: {
    Mutation: {
      updateToken(_result, args, cache, _info) {
        cache.writeFragment(
          gql`
            fragment _ on TeamType {
              id
              token
            }
          `,
          { id: args.id, token: args.token },
        );
      },
      tokenAuth(result, _args, cache, _info) {
        console.log(result, cache);
        updateCache<LoginMutation, MeQuery>(
          cache,
          { query: MeDocument },
          result,
          (r, q) => {
            console.log(r, cache);
            if (r.tokenAuth?.errors || !r.tokenAuth?.user) return q;
            const user = r.tokenAuth?.user;
            return {
              me: {
                __typename: "UserType",
                id: user.id,
                email: user.email,
                username: user.username,
                isStaff: user.isStaff,
                lastLogin: user.lastLogin,
              },
            };
          },
        );
      },
      logout(result, _args, cache, _info) {
        updateCache<any, MeQuery>(cache, { query: MeDocument }, result, () => ({
          me: null,
        }));
        invalidateTeams(cache);
      },
      createTeam(result, _args, cache, _info) {
        updateCache<CreateTeamMutation, TeamsQuery>(
          cache,
          { query: TeamsDocument },
          result,
          (r, q) => {
            console.log(r, q);
            if (!r.createTeam?.team) return q;
            q.teams?.push(r.createTeam.team);
            return q;
          },
        );
      },
      deleteTeam(result, args, cache, _info) {
        updateCache<RemoveTeamMutation, TeamsQuery>(
          cache,
          { query: TeamsDocument },
          result,
          (r, q) => {
            if (!r.deleteTeam?.ok) return q;
            q.teams = q.teams?.filter(
              x => x?.id === (args as RemoveTeamMutationVariables).id,
            );
            return q;
          },
        );
        cache.invalidate({
          __typename: "TeamType",
          id: (args as RemoveTeamMutationVariables).id,
        });
      },
      revokeToken(_result, args, cache, _info) {
        const vars = args as RevokeTokenMutationVariables;
        cache.writeFragment(
          gql`
            fragment _ on TeamType {
              id
              token
            }
          `,
          { id: vars.id, token: "" },
        );
      },
      updateTeam(_result, args, cache, _info) {
        const vars = args as UpdateTeamMutationVariables;
        cache.writeFragment(
          gql`
            fragment _ on TeamType {
              id
              name
              contactEmail
              firstName
              lastName
            }
          `,
          { ...vars },
        );
      },
    },
  },
});

const fetchOptions = () => {
  const token = getToken();
  const header = token ? `JWT ${token}` : "";
  return {
    headers: { Authorization: header },
  };
};

const errorExchange: Exchange = ({ forward }) => ops$ => {
  return pipe(
    forward(ops$),
    tap(({ error }) => {
      if (error?.message.includes("auth"))
        navigate("/admin/login", { replace: true });
    }),
  );
};

// urql client
const client = createClient({
  url,
  requestPolicy: "cache-and-network",
  fetchOptions,
  exchanges: [
    subExchange,
    customCacheExchange,
    dedupExchange,
    multipartFetchExchange,
    errorExchange,
  ],
});

export const ApiProvider: React.FC<{}> = ({ children }) => {
  return <Provider value={client}>{children}</Provider>;
};
