import React from "react";
import { Box, Text } from "grommet";
import Spinner from "react-loader-spinner";

export default function Loader() {
  return (
    <Box
      pad="large"
      align="center"
      height="100vh"
      justify="center"
      fill="horizontal"
      direction="column"
    >
      <Spinner width={60} height={60} color="primary" type="Circles" />
      <Text margin={{ top: "medium" }}>Bitte warten...</Text>
    </Box>
  );
}
