import React, { Suspense } from "react";
import { Router } from "@reach/router";

// components
import Loader from "../UI/Loader";
import Route from "./Route";

// utils
import routes from "./routes";

export default function AppRouter() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        {routes.map((r, i) => (
          <Route key={i} path={r.path} component={r.component} />
        ))}
      </Router>
    </Suspense>
  );
}
