import { navigate } from "@reach/router";
import React, { createContext, useCallback, useContext } from "react";

// utils
import {
  TeamInput,
  useCreateTeamMutation,
  useRemoveTeamMutation,
  useRevokeTokenMutation,
  useUpdateTokenMutation,
} from "../generated/graphql";
import { SnackbarContext } from "./snackbar";

// Context
type Context = {
  creatingTeam?: boolean;
  updatingToken?: boolean;
  revokingToken?: boolean;
  removingTeam?: boolean;
  createTeam(input: TeamInput): Promise<void>;
  updateToken(teamId: string, newToken: string): Promise<boolean>;
  revokeToken(teamId: string): Promise<void>;
  removeTeam(id: string, name?: string): Promise<void>;
};
const initialContext: Context = {
  createTeam: _ => Promise.resolve(),
  updateToken: (_, _1) => Promise.resolve(false),
  revokeToken: _ => Promise.resolve(),
  removeTeam: _ => Promise.resolve(),
};
export const TeamsContext = createContext<Context>(initialContext);

type Props = {
  children: any;
};
export function TeamsProvider({ children }: Props) {
  const { show } = useContext(SnackbarContext);

  // mutations
  const [
    { fetching: creatingTeam },
    createTeamMutation,
  ] = useCreateTeamMutation();
  const [
    { fetching: revokingToken },
    revokeTokenMutation,
  ] = useRevokeTokenMutation();
  const [
    { fetching: updatingToken },
    updateTokenMutation,
  ] = useUpdateTokenMutation();

  const [
    { fetching: removingTeam },
    removeTeamMutation,
  ] = useRemoveTeamMutation();

  // Methods
  const createTeam = useCallback(
    async (input: TeamInput) => {
      const { error } = await createTeamMutation({ input });
      if (error) {
        console.log(error);
        return show("Team konnte nicht erstellt werden");
      }
      show(`Team ${input.name} erstellt.`);
    },
    [show, createTeamMutation],
  );

  const updateToken = useCallback(
    async (id: string, token: string) => {
      const { error } = await updateTokenMutation({ id, token });
      const message = error
        ? "Token konnte nicht aktualisiert werden"
        : "Token aktualisiert";
      show(message);
      return !error;
    },
    [show, updateTokenMutation],
  );

  const revokeToken = useCallback(
    async (id: string) => {
      "Sind Sie sicher, dass Sie das Token für";
      const ask = "Are you sure you wish to revoke this team's token?";
      if (!window.confirm(ask)) return;

      const { error } = await revokeTokenMutation({ id });
      const message = error
        ? "Token konnte nicht widerrufen werden"
        : "Token wurde widerruffen";
      show(message);
    },
    [show, revokeTokenMutation],
  );

  const removeTeam = useCallback(
    async (id: string, name?: string) => {
      const ask = name
        ? `Sind Sie sicher, dass Sie den Team ${name} enternen wollen?`
        : "Sind Sie sicher, dass Sie diesen Team enternen wollen?";
      if (!window.confirm(ask)) return;

      const { error } = await removeTeamMutation({ id });
      if (error) return show("Team konnte nicht entfernt werden");

      navigate("/admin/dashboard", { replace: true });
      show("Team erfolgreich entfernt");
    },
    [show, removeTeamMutation],
  );

  const value: Context = {
    creatingTeam,
    updatingToken,
    revokingToken,
    removingTeam,
    createTeam,
    updateToken,
    revokeToken,
    removeTeam,
  };

  return (
    <TeamsContext.Provider value={value}>{children}</TeamsContext.Provider>
  );
}
