import { navigate } from "@reach/router";
import React, { useContext, useState, useEffect } from "react";
import { Home, Upload, Download, Login, UserAdmin, DocumentExcel, BarChart } from "grommet-icons";
import { Nav, Button, Grid, Box, Sidebar, Text } from "grommet";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import sapvLogo from "../../sapv-logo.png";

// components
import AppRouter from "../routes/AppRouter";

// utils
import { UserContext } from "../store/user";

// types
type Page = {
  path: string;
  label: string;
  icon: JSX.Element;
  protection?: "always" | "guest" | "user";
};
const pages: Page[] = [
  { label: "Start", icon: <Home />, path: "/" },
  { label: "Auswertung", icon: <BarChart />, path: "/visualization" },
  { label: "Datei hochladen", icon: <Upload />, path: "/upload" },
  { label: "Datei ansehen", icon: <Download />, path: "/feedback" },
  {
    label: "Admin Login",
    icon: <Login />,
    path: "/admin/login",
    protection: "guest",
  },
  {
    label: "Neuste Dateien",
    icon: <DocumentExcel />,
    path: "/admin/uploads",
    protection: "user",
  },
  {
    label: "Teams",
    icon: <UserAdmin />,
    path: "/admin/dashboard",
    protection: "user",
  },
];

export default function Navigation() {
  // state
  const { hasAccess } = useContext(UserContext);
  const [expanded, setExpanded] = useState(true);

  // effect -> save sidebar expanded state
  useEffect(() => {
    const item = localStorage.getItem("expanded");
    if (!item) localStorage.setItem("expanded", "true");
    else setExpanded(item === "true");
  }, []);

  // sidebar header
  // -> with/without text
  // -> always with button to expand/collapse
  function _renderHeader() {
    const pad = expanded ? "small" : undefined;
    return (
      <>
        <Box
          fill="horizontal"
          justify={expanded ? "between" : "center"}
          direction="row"
          align="center"
          pad={{ left: pad, top: "small" }}
        >
          {expanded ? (
            <Text
              margin={{ right: "small" }}
              style={{ maxWidth: 300 }}
              wordBreak="normal"
              weight="bold"
              size="20px"
            >
              SAPV
            </Text>
          ) : null}
          <Button
            hoverIndicator
            icon={expanded ? <FaChevronLeft /> : <FaChevronRight />}
            onClick={() => {
              localStorage.setItem("expanded", !expanded + "");
              setExpanded(prev => !prev);
            }}
          />
        </Box>
        <img src={sapvLogo} width="200" alt="SAPV Logo" /> 
      </>
    );
  }

  // navigation buttons
  // -> expanded: square buttons (text + icons)
  // -> collapsed: icon buttons
  function _renderNav() {
    // icon buttons
    if (!expanded)
      return (
        <Nav gap="medium">
          {pages.map((p, i) => {
            if (p.protection === 'guest' && hasAccess) return null;
            if (p.protection === 'user' && !hasAccess) return null;
            return (
              <Button
                key={i}
                icon={p.icon}
                hoverIndicator
                fill="horizontal"
                color="secondary"
                //active={path === p.path}
                onClick={() => navigate(p.path)}
              />
            );
          })}
        </Nav>
      );

    // square buttons
    return (
      <Nav gap="medium">
        {pages.map((p, i) => {
          if (p.protection === 'guest' && hasAccess) return null;
          if (p.protection === 'user' && !hasAccess) return null;
          return (
            <Button
              key={i}
              icon={p.icon}
              hoverIndicator
              label={p.label}
              fill="horizontal"
              color="secondary"
              //active={path === p.path}
              onClick={() => navigate(p.path)}
              style={{ padding: "10px 20px", borderRadius: 0 }}
            />
          );
        })}
      </Nav>
    );
  }

  return (
    <Box height="100vh">
      <Grid
        fill="vertical"
        rows={["flex"]}
        columns={["auto", "flex"]}
        areas={[
          { name: "sidebar", start: [0, 0], end: [0, 0] },
          { name: "main", start: [1, 0], end: [1, 0] },
        ]}
      >
        <Box gridArea="sidebar">
          <Sidebar background="primary" header={_renderHeader()} align="center">
            {_renderNav()}
          </Sidebar>
        </Box>
        <Box
          gridArea="main"
          background="background"
          fill="vertical"
          overflow="auto"
        >
          <AppRouter />
        </Box>
      </Grid>
    </Box>
  );
}
