import React, { createContext, useCallback, useMemo, useState } from "react";

// types
type Context = {
  show: (t: string, time?: number) => void;
};
const initialContext: Context = {
  show: (_, _1) => {},
};

// timers
let timer1: NodeJS.Timeout, timer2: NodeJS.Timeout;
const timeout = 5 * 1000;

type Props = {
  children: any;
};
export const SnackbarContext = createContext<Context>(initialContext);
export function SnackbarProvider({ children }: Props) {
  // state
  const [text, setText] = useState("");
  const [shown, setShown] = useState(false);
  const [hidden, setHidden] = useState(true);

  // methods
  const show = useCallback((t: string, time: number = timeout) => {
    clearTimeout(timer1);
    clearTimeout(timer2);
    setText(t);
    setShown(true);
    setHidden(false);
    timer1 = setTimeout(() => {
      setShown(false);
      timer2 = setTimeout(() => {
        setHidden(true);
        setText("");
      }, 300);
    }, time);
  }, []);

  // styles
  const styles = useMemo((): React.CSSProperties => {
    if (shown) return { opacity: 1, transform: "translateY(0px)" };
    return { opacity: 0, transform: "translateX(50px)" };
  }, [shown]);

  return (
    <SnackbarContext.Provider value={{ show }}>
      {hidden ? null : (
        <div
          style={{
            transform: "translateY(50px)",
            transition: ".3s ease-in-out",
            backgroundColor: "black",
            padding: "20px 30px",
            position: "fixed",
            maxWidth: "600px",
            borderRadius: 5,
            color: "white",
            zIndex: 1000,
            bottom: 20,
            right: 20,
            opacity: 0,
            ...styles,
          }}
        >
          <span>{text}</span>
        </div>
      )}
      {children}
    </SnackbarContext.Provider>
  );
}
